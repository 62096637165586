<template>
  <div class="finish">
    <div class="top">
      <div class="holder -text-center">
        <div>
          <h2 class="-text-white">Slutför ditt bidrag</h2>
          <p class="-body-1">Ge din smoothiebowl ett namn och skicka in ditt bidrag!</p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="holder">
        <img class="blender" src="@/assets/img/blender.svg" alt="" />
        <div class="my-recipe">
          <a href="javascript:;" @click="showRecipe">Visa mitt recept</a>
        </div>
        <form class="form" v-on:submit.prevent="send" autocomplete="off" novalidate="">
          <div>
            <label class="-caption -text-uppercase" for="name">Namn på din smoothie<span class="-text-red">*</span></label>
            <span v-if="showInvalidMessage && this.$v.name.$invalid" class="error-message -caption -text-uppercase -text-red">Du måste ange ett namn på din smoothie</span>
            <input type="text" name="name" v-model="name" placeholder="Namn på smoothie" />
          </div>
          <div>
            <label class="-caption -text-uppercase" for="email">Din epost<span class="-text-red">*</span></label>
            <span v-if="showInvalidMessage && this.$v.email.$invalid" class="error-message -caption -text-uppercase -text-red">Du måste ange din epostadress</span>
            <input type="email" id="email" v-model="email" placeholder="namn@epost.com" />
          </div>
          <div class="rules-terms">
            <div class="checkbox">
              <span v-if="showInvalidMessage && this.$v.termsAccepted.$invalid" class="error-message -caption -text-uppercase -text-red">Du måste godkänna villkoren</span>
              <div>
                <input type="checkbox" v-model="termsAccepted" id="terms" />
                <label class="-body-2" for="terms">Jag godkänner villkoren för denna kampanj<span class="-text-red">*</span><br /><a href="javascript:;" class="-text-green" @click="showRules()">Regler & Villkor</a></label>
              </div>
            </div>
            <div class="checkbox">
              <div>
                <input type="checkbox" id="newsletter" v-model="acceptNewsletter" />
                <div>
                  <label for="newsletter" class="-body-3">
                    <strong>Nyhetsbrev:</strong> Önskar du få exklusiva erbjudanden om produkter från <a href="https://www.orkla.com/legal/se/orkla-bolag-som-delar-ansvaret-behandling-av-personuppgifter/" target="_blank" rel="nofollow" class="-text-green -text-bold -text-no-underline">Orkla</a>?
                    Anmäl dig till vårt nyhetsbrev och bli bland de första mottagarna. <a href="javascript:;" @click="clickedShowMoreNewsletterInfo">Mer information.</a>
                    <div class="inner" v-if="showMoreNewsletterInfo">
                      <p>Orkla är en ledande leverantör av märkesvaror till svenska konsumenter. <a href="https://www.orkla.com/legal/se/orkla-bolag-som-delar-ansvaret-behandling-av-personuppgifter/" target="_blank" rel="nofollow" class="-text-green -text-bold -text-no-underline">Svenska Orkla-bolag</a> är gemensamt ansvariga för behandlingen av personuppgifter.</p>
                      <p>Om du tackar ja till att motta nyhetsbrev och erbjudanden från oss, kan även andra bolag inom Orkla-koncernen skicka relevanta erbjudanden och information till dig, exempelvis om sina produkter, tjänster och kundundersökningar.</p>
                      <p>Vi lovar att se till att du inte kommer få fler än två nyhetsbrev per vecka. Periodvis kan vi dock komma att skicka fler nyhetsbrev, exempelvis under Black Friday-rea.</p>
                    </div>
                    <hr />
                    <strong>Personanpassning:</strong> Vi vill ge dig de bästa erbjudandena och relevant information anpassad för dig, baserad på information om dig hämtad från olika källor. Det är viktigt för att förbättra vår kommunkation och ge dig relevanta erbjudanden. Här kan du hitta <a href="javascript:;" @click="clickedShowPersonalizationInfo">mer information och välja att stänga av denna funktion.</a>
                    <div class="personalization-info" v-if="showPersonalizationInfo">
                      <div class="checkbox">
                        <div>
                          <input type="checkbox" v-model="personalization" id="personalization" />
                          <label class="-body-3" for="personalization">Jag vill motta generella nyhetsbrev och erbjudanden istället för personanpassade nyhetsbrev och erbjudanden.</label>
                        </div>
                      </div>
                      <div class="inner">
                        <p>Personanpassade nyhetsbrev och erbjudanden innebär att vi kan använda:</p>
                        <ul>
                          <li>
                            <p class="-body-3">Information du har angivit om dig själv och dina preferenser. Till exempel från när du gick med i våra kampanjer, medlemstjänster och deltar i marknadsundersökningar.</p>
                          </li>
                          <li>
                            <p class="-body-3">Registrerade klick i e-mails från oss och ditt då efterföljande beteende på våra hemsidor.</p>
                          </li>
                          <li>
                            <p class="-body-3">Din köphistorik på Orkla-bolags web-shop.</p>
                          </li>
                          <li>
                            <p class="-body-3">Information om dig som ett tredje parts-bolag lagligen samlat och delat med oss. Sådan information kan vara:</p>
                            <ul>
                              <li>
                                <p class="-body-3">Namn, adress, kön, inkomst, medlemskap i organisationer, klubbar etc. från så kallade adressmäklare.</p>
                              </li>
                              <li>
                                <p class="-body-3">Din köphistorik hos återförsäljare.</p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p class="-body-3">Anonym statistik. Det är exempelvis generisk information om preferenserna hos specifika segment av befolkningen. Valet av segmenterad information är baserad på variabler som inkomst, kön och vistelseort.</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                    <strong>Annonser:</strong> För att göra vår online-marknadsföring (exempelvis online-tidningar och sociala medier) mer relevant för mottagarna önskar vi använda din e-mailadress och information från din kontakt med oss. Här kan du hitta <a href="javascript:;" @click="clickedAdsInfo">mer information och ändra alternativ till denna funktion.</a>
                    <div class="ads-info" v-if="showAdsInfo">
                      <div class="checkbox">
                        <div>
                          <input type="checkbox" v-model="regads" id="regads" />
                          <label class="-body-3" for="regads">Jag vill stänga av användningen av min e-mailadress och information från min kontakt med Orkla för personanpassade annonser online.</label>
                        </div>
                      </div>
                      <div class="inner">
                        <p class="-body-3">Denna funktion låter oss använda din e-mailadress och information från din kontakt med oss för att visa annonser på vissa typer av webbsidor, exempelvis sociala medier, sökmotorer och online-tidningar. Detta utgör ett berättigat intresse för oss som annonsör. Det sker på villkor att du mottar tillräcklig information och kan stänga av funktionen, samt att det finns tillräcklig informationssäkerhet.</p>
                      </div>
                      <div class="inner">
                        <p>Information från din kontakt med oss kan inkludera:</p>
                        <ul>
                          <li>
                            <p class="-body-3">Köp av vissa produkter</p>
                          </li>
                          <li>
                            <p class="-body-3">Registrerade klick i e-mail från oss och ditt efterföljande beteende på våra hemsidor</p>
                          </li>
                          <li>
                            <p class="-body-3">Att du har fått vissa nyhetsbrev</p>
                          </li>
                          <li>
                            <p class="-body-3">Deltagande i våra annonskampanjer</p>
                          </li>
                        </ul>
                        <p class="-body-3 -i">Ett exempel på hur denna funktion fungerar: Låt oss säga att du har deltagit i en tävling avseende en chokladprodukt. Efter tävlingen kan annonsören skicka din och andras e-mailadresser som deltagit i tävlingen till någon som ger ut en online-tidning med annonser. Därefter kan online-tidningen styras så att en annons om chokladprodukter visas för personer som - enligt online-tidningens egen användardata och analys - har liknande intressen som de som har deltagit i tävlingen. Visningen av annonsen är baserad på en gissning att andra personer med liknande intresseprofil kan vara intresserade av samma produkt. Analysen som har utförts av online-tidningen är endast baserad på information som de har om sina användare och sådana annonser visas endast för personer som har samtyckt till att se personanpassade annonser.</p>
                      </div>
                    </div>
                    <hr />
                    Du kan när som helst kontakta oss för att sluta skicka nyhetsbrev och <strong>stänga ner funktionerna som nämns ovan</strong>. Se mer om detta och kompletterande information <a href="https://www.orkla.com/legal/se/sekretess-och-integritet/" target="_blank" rel="nofollow" class="-text-green -text-bold -text-no-underline">Orklas behandling av personuppgifter.</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Button class="btn">Skicka in mitt bidrag</Button>
          <p v-if="showErrorMessage" class="-body-1 -error">Något gick fel. Ladda om sidan och försök igen.</p>
        </form>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, sameAs } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { Button } from '@/components'

export default {
  compontents: {
    Button,
  },
  computed: {
    ...mapGetters({
      ingredients: 'recipe/ingredients',
      recipeSent: 'recipe/recipeSent',
      recipeSentError: 'recipe/recipeSentError',
    }),
    name: {
      get() {
        return this.$store.state.recipe.name
      },
      set(value) {
        this.$store.dispatch('recipe/setRecipeName', value)
      }
    },
    email: {
      get() {
        return this.$store.state.recipe.email
      },
      set(value) {
        this.$store.dispatch('recipe/setRecipeEmail', value)
      }
    },
  },
  data: () => ({
    personalization: false,
    termsAccepted: false,
    regads: false,
    acceptNewsletter: false,
    showErrorMessage: false,
    showInvalidMessage: false,
    sending: false,
    showMoreNewsletterInfo: false,
    showPersonalizationInfo: false,
    showAdsInfo: false,
  }),
  mounted() {
    if (this.ingredients.length === 0) {
      this.showIngrediensModal = true
      this.$router.push({ name: 'noIngredientsAdded' })
    }
  },
  methods: {
    showRules() {
      this.$store.dispatch('rule/showRules', { show: true, goodFood: false })
    },
    clickedAdsInfo() {
      this.showAdsInfo = !this.showAdsInfo
    },
    clickedShowPersonalizationInfo() {
      this.showPersonalizationInfo = !this.showPersonalizationInfo
    },
    clickedShowMoreNewsletterInfo() {
      this.showMoreNewsletterInfo = !this.showMoreNewsletterInfo
    },
    showRecipe() {
      this.$store.dispatch('recipe/showRecipe', true)
    },
    async send() {
      if (!this.$v.$invalid) {
        this.showErrorMessage = false
        this.showInvalidMessage = false
        this.sending = true

        const { acceptNewsletter, personalization, regads } = this

        await this.$store.dispatch('recipe/sendRecipe', {
          acceptNewsletter,
          personalization,
          regads,
         })
      } else {
        this.showInvalidMessage = true
      }
    }
  },
  mixins: [
    validationMixin,
  ],
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    termsAccepted: {
      sameAs: sameAs( () => true ) 
    },
  },
  watch: {
    recipeSent(recipeSent, previous) {
      this.sending = false
      if (recipeSent != previous) {
        this.$store.dispatch('recipe/resetRecipe')
        window.location.href = process.env.VUE_APP_THANKYOU_URL
      }
    },
    recipeSentError(recipeError, previous) {
      this.sending = false
      if (recipeError != previous) {
        this.showErrorMessage = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.finish {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top {
  @include rem(padding, 24px 0 76px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.holder {
  flex-direction: column;
}

.btn {
  @include rem(padding, 12px 20px);
  @include rem(font-size, 16px);
  @include rem(line-height, 24px);
  @include rem(border-radius, 2px);
  min-width: 280px;
  text-decoration: none;
  outline: 0;
  display: inline-block;
  justify-content: center;
  text-align: center;
  font-weight: 350;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 0;
  cursor: pointer;
  background-color: $green;
  color: $white;

  &:hover {
    background-color: $green-hover;
  }

  &:disabled {
    background-color: $green-90;
    cursor: default;

    &:hover {
      background-color: $green-90;
    }
  }
}

.blender {
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%);
}

.bottom {
  @include rem(padding, 80px 0);
  position: relative;
  width: 100%;
  background-color: $white;
  flex: 1;
}

.my-recipe {
  @include rem(margin, 0 0 $gutter * 2);
  width: 100%;
  display: flex;
  justify-content: center;

  a {
    text-transform: uppercase;
  }
}

.-error {
  @include rem(margin, $gutter 0);
  display: block;
  text-align: center;
}

.checkbox {
  @include rem(margin-bottom, $gutter);

  hr {
    @include rem(margin, $gutter 0);
    border: 1px solid $grey;
  }

  > div {
    @include rem(margin-bottom, $gutter / 2);
    display: flex;
    align-items: flex-start;
  }

  input {
    @include rem(margin, 4px $gutter / 2 0 0);
    width: auto;
  }
}

h2 {
  @include rem(margin-bottom, $gutter / 2);
}

.rules-terms {
  @include rem(margin-top, $gutter / 2);
}

.inner {
  @include rem(margin, $gutter);

  p {
    @include rem(font-size, 12px);
    @include rem(line-height, 20px);
    @include rem(margin-bottom, $gutter / 2);
  }

  .-body-3 {
    margin-bottom: 0;

    &.-i {
      font-style: italic;
    }
  }

  ul {
    @include rem(padding-left, $gutter);
    @include rem(margin-bottom, $gutter);
    list-style-type: disc;

    ul {
      @include rem(padding-left, $gutter);
      list-style: circle;
      margin-bottom: 0;
    }
  }
}


.personalization-info,
.ads-info {
  @include rem(margin-top, $gutter / 2);
}

@media #{$wide} {
  .top {
    @include rem(min-height, 270px);
  }

  h2 {
    @include rem(margin-bottom, $gutter);
  }

  .bottom {
    @include rem(padding, 100px 0);
  }

  .rules-terms {
    @include rem(margin-top, $gutter);
  }

  .inner {
    @include rem(margin, $gutter $gutter * 2);
  }

  .personalization-info,
  .ads-info {
    @include rem(margin-top, $gutter);
  }
}
</style>